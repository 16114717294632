import React, { useState } from 'react';
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { imgDiv } from "./1plus31.module.css"
import { howToRead } from "./1plus31.module.css"
import { howToReadMainTitle } from "./1plus31.module.css"
import { howToReadPartTitle } from "./1plus31.module.css"
import { gyoukan } from "./1plus31.module.css"
import { title } from "./1plus31.module.css"
import { desc } from "./1plus31.module.css"
import { desc2 } from "./1plus31.module.css"
import { desc3 } from "./1plus31.module.css"
import { article } from "./1plus31.module.css"
import { panel } from "./1plus31.module.css"
import { openButtonDiv } from "./1plus31.module.css"
import { openButton } from "./1plus31.module.css"
import { openButtonDiv2 } from "./1plus31.module.css"
import { openButton2 } from "./1plus31.module.css"


import getLanguage from "../../utilt/getLangueage"

import panelImg from "../../images/1plus31/works_1plus31_panel.jpg"
import controlPanelImg from "../../images/1plus31/control-panel.jpg"
import bookmarkImg from "../../images/1plus31/bookmark.jpg"
//import seoImg from "../../images/seo/seo_1plus31.jpg"

/**
 * open file function
 */
function open1plus31() {
  window.open("https://www.drawinghell.com/1plus31/", "", "width=512,height=758");
}


/**
 * images
 */
const ControlPanelImg = props => {
  return (
    <div
      className={imgDiv}
    >
      <img
        src={controlPanelImg}
        alt="control panel"
        width={812 * Math.min(props.winSize.width / 960, 0.9)}
        height={136 * Math.min(props.winSize.width / 960, 0.9)}
      />
    </div>
  )
}

const BookmarklImg = props => {
  return (
    <div
      className={imgDiv}
    >
      <img
        src={bookmarkImg}
        alt="bookmark"
        width={236 * Math.min(props.winSize.width / 720, 0.8)}
        height={102 * Math.min(props.winSize.width / 720, 0.8)}
      />
    </div>
  )
}


/**
* How To Read
*/
const HowToRead = props => {
  if (props.lang !== "ja") {
    return (
      <div
        className={howToRead}
      >
        <h5
          className={howToReadMainTitle}
        >
          How to read this book
        </h5>
        <h6
          className={howToReadPartTitle}
        >
          — Mouse Event —
        </h6>
        <li>
          Click at upper part of the screen to display the <a href="#controlPanel">control panel</a>.
        </li>
        <li>
          Clicking the screen with the control panel displayed will retract the control panel.
        </li>
        <li>
          Left-clicking on the screen with a Waka is displayed will let the Waka disappear.
        </li>
        <li>
          Right-clicking on the screen (long touch for touch devices) when a Waka is not displayed will display the Waka.
        </li>
        <li>
          Right-click on the screen with a Waka displayed (long touch for touch devices), information about the Waka will be displayed.
        </li>
        <li>
          Otherwise (neither waka nor control panel is displayed & below the top of the screen),
          <p>Left click on the right side of the screen = Go to the next page.</p>
          <p>Left click on the left side of the screen = go to the previous page.</p>
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="controlPanel"
        >
          — Control Panel —
        </h6>
        <ControlPanelImg winSize={props.winSize} />
        <p>
          In order from the left
        </p>
        <li>
          Index : Move to the index page.
        </li>
        <li>
          Replay : Reload the current page.
        </li>
        <li>
          Previous Page : Move to the previous page.
        </li>
        <li>
          Next Page : Move to the next page.
        </li>
        <li>
          Setting : Move to the <a href="#settingPage">setting page</a>.
        </li>
        <li>
          Bookmark : <a href="#bookMark">Bookmarks</a>  are displayed. Click the button again to retract the bookmarks.
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="bookMark"
        >
          — Bookmark —
        </h6>
        <BookmarklImg winSize={props.winSize} />
        <li>
          Click on a new bookmark (no number is displayed) to register the page in the bookmark.
        </li>
        <li>
          Click near the tip of a bookmark (the direction with the number on it) to go to the number page.
        </li>
        <li>
          Click near the root of a bookmark to delete it.
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
        >
          — Keyboard Event —
        </h6>
        <li>
          p or → : Move to the previous page.
        </li>
        <li>
          n or ← : Move to the next page.
        </li>
        <li>
          r : Reload the current page.
        </li>
        <li>
          i : Move to the index page.
        </li>
        <li>
          s : Move to the <a href="#settingPage">setting page</a>.
        </li>
        <li>
          Backspace or Alt + ← or u : Return to the page you were looking at before the page (The history stored only one page.)
        </li>
        <li>
          Enter : Let waka disappear when waka is displayed (same as left click).
        </li>
        <li>
          w : Displays the waka when the waka is not displayed, and displays waka information when the waka is displayed (same as right-click).
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
        >
          — Swipe —
        </h6>
        <p>
          On touch devices, you can move pages by swiping left or right.
        </p>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="settingPage"
        >
          — Setting Page —
        </h6>
        <li>
          Language : English or Japanese can be selected.
        </li>
        <li>
          Waka Display :  If you select "off", Wakas will not be displayed.
        </li>
        <li>
          Waka Animation Speed : You can change the animation speed when the Wakas appear. The default value is 5. The higher the value, the slower the speed.
        </li>
        <p>
          To return from the setting page, use the "back" button or the shortcut key (Backspace or Alt + ← or u ). 
        </p>
      </div>
    )
  } else {
    return (
      <div
        className={howToRead}
      >
        <h5
          className={howToReadMainTitle}
        >
          この本の読み方
        </h5>
        <h6
          className={howToReadPartTitle}
        >
          — マウス操作 —
        </h6>
        <li>
          画面上方をクリックすると <a href="#controlPanel">コントロール・パネル</a> が現れます。
        </li>
        <li>
          コントロール・パネルが表示された状態で画面をクリックすると、コントロール・パネルが収納されます。
        </li>
        <li>
          和歌が表示された状態で画面を左クリックすると、和歌が消えます。
        </li>
        <li>
          和歌が表示されていない状態で画面を右クリック（タッチデバイスの場合はロングタップ）すると、和歌が表示されます。
        </li>
        <li>
          和歌が表示された状態で画面を右クリック（タッチデバイスの場合はロングタップ）すると、その和歌についての情報が表示されます。
        </li>
        <li>
          それ以外（和歌もコントロールパネルも表示されていない＆画面上方よりも下）の場合は、
          <p>画面右側を左クリック＝次の頁に進みます。</p>
          <p>画面左側を左クリック＝前の頁に進みます。</p>
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="controlPanel"
        >
          — コントロール・パネル —
        </h6>
        <ControlPanelImg winSize={props.winSize} />
        <p>
          左から順番に
        </p>
        <li>
          目次：目次ページに移動します。
        </li>
        <li>
          リプレイ：同じページをリロードします。
        </li>
        <li>
          前のページ：前のページに移動します。
        </li>
        <li>
          次のページ：次のページに移動します。
        </li>
        <li>
          設定：<a href="#settingPage">設定ページ</a> に移動します。
        </li>
        <li>
          しおり：<a href="#bookMark">しおり</a> が表示されます。もう一度クリックするとしおりが格納されます。
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="bookMark"
        >
          — しおり —
        </h6>
        <BookmarklImg winSize={props.winSize} />
        <li>
          新規のしおり（数字が表示されていないしおり）をクリックすると、そのページがしおりに登録されます。
        </li>
        <li>
          しおりの先端近く（数字の書いてある側）をクリックすると、表記された数字のページに移動します。
        </li>
        <li>
          しおりの根元近くをクリックすると、そのしおりが削除されます。
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
        >
          — キーボード操作 —
        </h6>
        <li>
          p または← : 前のページに移動します。
        </li>
        <li>
          n または→ : 次のページに移動します。
        </li>
        <li>
          r : 同じページをリロードします。
        </li>
        <li>
          i : 目次ページに移動します。
        </li>
        <li>
          s : <a href="#settingPage">設定ページ</a> に移動します。
        </li>
        <li>
          Backspace または Alt + → または u : 一つ前に見ていたページに戻ります（履歴は一頁のみ）。
        </li>
        <li>
          Eenter : 和歌表示時に和歌を消去します（左クリックに同じ）。
        </li>
        <li>
          ｗ : 和歌非表示時に和歌を表示、和歌表示時に和歌情報を表示します（右クリックに同じ）。
        </li>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
        >
          — スワイプ —
        </h6>
        <p>
          タッチデバイスでは左右のスワイプでページ移動ができます。
        </p>
        <p className={gyoukan}></p>
        <h6
          className={howToReadPartTitle}
          id="settingPage"
        >
          — 設定ページ —
        </h6>
        <li>
          言語：英語か日本語を選択できます。
        </li>
        <li>
          和歌の表示：「しない」を選択すると和歌が表示されなくなります。
        </li>
        <li>
          和歌アニメ速度：和歌の出現時のアニメーション速度を変えられます。デフォルト値は５です。値が大きいほどゆっくりになります。
        </li>
        <p>
          設定ページから本画面に戻るときは「戻る」ボタンかショートカットキー（Backspace or Alt + ← or u ）を使用してください。
        </p>
      </div>
    )
  }


}




/**
 * ページ
 */
const Oneplus31 = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }

  return (
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Seo
        title="1(+31)"
        description={lang !== "ja" ?
          "Collection of 1 koma manga(one-panel cartoons) combining drawings made using the automatism method with Waka (ancient Japanese poems), and then adding animation to the pictures using JavaScript." :
          "オートマティスムの手法で描いたドローイングに古の和歌を組み合わせて、さらにJavaScriptで絵に動きを付けた一コマ漫画集"
        }
        lang={lang}
        image="https://www.drawinghell.com/ogp_images/seo_1plus31.jpg"
        pathname="/work/1plus31"
      />
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1 className={title}>
          {lang !== "ja" ? "1(+31)" : "1(+31)"}
        </h1>
        <p className={desc2}>
          {lang !== "ja" ?
            `Collection of 1 koma manga(one-panel cartoons) combining drawings made using the automatism method with Waka (ancient Japanese poems), and then adding animation to the pictures using JavaScript` :
            `オートマティスムの手法で描いたドローイングに古の和歌を組み合わせて、さらにJavaScriptで絵に動きを付けた一コマ漫画集`}
        </p>
        <p className={desc}>
          {lang !== "ja" ?
            `1 koma Manga, Digital Picture Book, 2019` :
            `一コマ漫画、デジタル絵本、2019年`}
        </p>
        <div
          className={article}
        >
          <div
            className={panel}
            style={{
              background: `url(${panelImg}) repeat`,
              backgroundSize: "1561px 420px"
            }}
          ></div>


          <div
            className={openButtonDiv}
          >
            <button
              className={openButton}
              style={{
                letterSpacing: `${lang !== "ja" ? "0.5px" : "2.5px"}`
              }}
              type="submit"
              onClick={() => open1plus31()}
            >
              {lang !== "ja" ? "Open the book" : "本を開く"}
            </button>
          </div>

          <div
            className={`${openButtonDiv} ${openButtonDiv2}`}
          >
            <Link
              className={`${openButton} ${openButton2}`}
              style={{
                letterSpacing: `${lang !== "ja" ? "0.5px" : "1px"}`
              }}
              to="../1plus31_2"
            >
              {lang !== "ja" ? "List view page" : "一覧表示"}
            </Link>
          </div>
          <a href="https://docs.google.com/spreadsheets/d/1UCu6YsycaLa8x-aIMmFbS1h3abVZgo1qKhluO69qGrs"
            target="_blank"
            rel="noopener noreferrer"
            className={desc3}
          >
            {lang !== "ja" ? "Spreadsheet of Waka List (Japanese)" : "使用和歌一覧"}
          </a>


          <HowToRead
            className={howToRead}
            lang={lang}
            winSize={winSize}
          />

        </div>
      </Article>
    </Layout>
  )
}

export default Oneplus31
