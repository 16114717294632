// extracted by mini-css-extract-plugin
export var animateBackground = "_1plus31-module--animate-background--JEahp";
export var article = "_1plus31-module--article--4VJZB";
export var desc = "_1plus31-module--desc--0B-Uf";
export var desc2 = "_1plus31-module--desc2--dRBiT";
export var desc3 = "_1plus31-module--desc3--ZWCKO";
export var gyoukan = "_1plus31-module--gyoukan--xkIou";
export var howToRead = "_1plus31-module--howToRead--hFcNh";
export var howToReadMainTitle = "_1plus31-module--howToReadMainTitle--r99Fp";
export var howToReadPartTitle = "_1plus31-module--howToReadPartTitle--Gx02o";
export var imgDiv = "_1plus31-module--imgDiv--hZdVF";
export var openButton = "_1plus31-module--openButton--zK-6+";
export var openButton2 = "_1plus31-module--openButton2--VSk9S";
export var openButtonDiv = "_1plus31-module--openButtonDiv--t1tCz";
export var openButtonDiv2 = "_1plus31-module--openButtonDiv2--gCHjC";
export var panel = "_1plus31-module--panel--BMJrs";
export var title = "_1plus31-module--title--1v6EK";